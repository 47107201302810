import { useVertical } from 'lib/Hooks/useVertical';
import { useGeoLocation } from 'lib/Hooks/useGeoLocation';

import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { GATED_CONTENT } from 'lib/brandFeatures';

import { AUTHENTICATED } from 'lib/myNewsConstants';

import { useMyNewsStore } from 'store';

function isContentGated(content) {
  const { taxonomy: { additionalTerms } = {} } = content;
  return Boolean(additionalTerms?.find((term) => term.slug === 'gated-content'));
}

export function useGateAccess(content) {
  const authenticationState = useMyNewsStore((state) => state.authenticationState);
  const { vertical } = useVertical();
  const { isUsa } = useGeoLocation();

  const hasGatedContentFeature = getFeatureConfigForBrand(
    GATED_CONTENT,
    vertical,
  );

  const isGated = isContentGated(content);
  const isAuthenticated = authenticationState === AUTHENTICATED;

  /* Gated Feature is not enabled */
  if (!hasGatedContentFeature || !isGated) return true;

  return isAuthenticated && isUsa;
}
